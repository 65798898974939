'use client'

import { Dialog } from '@headlessui/react'
import cookieCutter from 'js-cookie'
import { useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import React, { useContext, useState } from 'react'

import clsxm from '@/lib/clsxm'
import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'

import { AuthContext } from '@/providers/AuthContext'
import { api } from '@/services/api'

import { ModalCloseButton } from './buttons/ModalCloseButton'
import FeaturedLocationsList from './FeaturedLocationsList'
import GoogleAutoCompleteWithCountry, { GoogleAutoCompleteWithCountryProps } from './GoogleAutoCompleteWithCountry'

export default function LocationModal({
  // locationInfo,
  isOpen,
  onClose,
}: {
  // locationInfo: LocationInfo
  isOpen: boolean
  onClose: CallableFunction
}) {
  const router = useRouter()
  const tracking = useTracking()
  const { t, lang } = useTranslation()

  const { locationInfo } = useContext(AuthContext)

  const [selectedNewLocation, setSelectedNewLocaton] = useState(locationInfo || null)

  const [isBusy, setIsBusy] = useState(false)

  const handleLocationSave = async () => {
    if (!selectedNewLocation) {
      return
    }

    setIsBusy(true)

    await api.updateLocationCookie(selectedNewLocation)

    cookieCutter.set('NEXT_LOCATION', selectedNewLocation.citySlug, { path: '/', expires: 365, sameSite: 'lax' })
    cookieCutter.set('NEXT_LOCATION_ID', selectedNewLocation.id.toString(), {
      path: '/',
      expires: 365,
      sameSite: 'lax',
    })
    cookieCutter.set('NEXT_LOCATION_NAME', selectedNewLocation.cityName, { path: '/', expires: 365, sameSite: 'lax' })

    tracking.track({ evt: 'Location Changed', data: { location: selectedNewLocation.cityName } })
    // setIsOpen(false)

    const nextPage = `/${lang}/${selectedNewLocation.citySlug}?location=${selectedNewLocation.id}`

    router.replace(nextPage)
    router.refresh()
    // window.location.href = nextPage
    setTimeout(() => {
      setIsBusy(false)
      onClose()
      // window.location.href = nextPage
    }, 3000)
  }

  const handleModalClose = () => {
    onClose()
  }

  const handleLocationModalStateChange: GoogleAutoCompleteWithCountryProps['onStateChange'] = (state) => {
    if (state === 'loading') {
      setSelectedNewLocaton(null)
    }
  }

  return (
    <>
      <Dialog as="div" className="relative z-50" open={isOpen} onClose={() => console.log('close')}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 h-screen w-full  lg:flex lg:items-center lg:justify-center">
          <Dialog.Panel className="scrollbar-hide relative h-screen w-full transform overflow-y-auto bg-white px-[10px] py-5 text-left align-middle shadow-xl transition-all lg:h-auto lg:w-[830px] lg:rounded-2xl lg:p-9">
            <div className="flex items-center justify-end">
              <ModalCloseButton className="self-end" onClose={handleModalClose} />
            </div>

            <div className="mb-3 flex flex-row justify-between">
              <span className="font-roboto text-text-800 text-2xl font-medium">{t(`Search for activities in`)}</span>
            </div>

            <GoogleAutoCompleteWithCountry
              type="user-location"
              addressText={locationInfo.formattedAddress}
              countryCode={locationInfo.countryCode}
              onLocationChange={(info) => setSelectedNewLocaton(info)}
              onStateChange={handleLocationModalStateChange}
            />

            <p className="mt-3 text-lg">
              {t(
                `Search by city, neighborhood or zip code, you will be able to see activities, meetings, trips and people near you`,
              )}
              .
            </p>

            <div className="mb-8 mt-8">
              <p className="font-roboto text-text-800 mb-3 text-2xl font-medium">{t(`Another location`)}:</p>

              <FeaturedLocationsList onSelect={setSelectedNewLocaton} />
            </div>
            <div className="sticky bottom-0 z-20 box-border  flex w-full self-end lg:relative lg:bottom-0 lg:items-center lg:justify-center">
              <Button
                isLoading={isBusy}
                disabled={!selectedNewLocation}
                type="button"
                className="w-full items-center justify-center lg:w-80 lg:max-w-sm"
                variant="primary"
                // size="md"
                onClick={handleLocationSave}>
                {t('Keep')}
              </Button>
            </div>
            <div className="h-10" />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}
